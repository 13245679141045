import { Container, Flex, Tag, BoxProps, Text, Image, Heading, useBreakpointValue } from '@chakra-ui/react'
import { FC, ReactNode, useMemo } from 'react'

import { FranceRegionsMap } from '@miimosa/common/helpers'
import { Carousel, Icon, TadaInno } from '@miimosa/design-system/components'

import carbonData from './carbon-projects-data'

const CarbonItem = ({
  title,
  type,
  region,
  place,
  co2,
  impacts,
  owner,
  description,
}: Record<string, number | string | string[] | ReactNode>) => {
  const RegionSvgUrl = FranceRegionsMap[region as string]

  return (
    <Flex direction="column" key={`testi_${title}`} bgColor="white" mx="3" my="4" borderRadius="6px" p="2">
      <Flex align="center" justifyContent="space-between" position="relative">
        <Flex align="flex-end">
          {RegionSvgUrl && <Image src={RegionSvgUrl} alt="region" w="60px" h="60px" />}
          <Flex direction="column">
            <Text size="lg" pr="13">
              {title}
            </Text>
            <Text size="sm" color="grey">
              {owner}
            </Text>
            <Flex position="relative">
              {/* <BrushArrow
                position="absolute"
                boxSize={8}
                color="primary.DEFAULT"
                top="-2px"
                transform="rotate(25deg)"
                // left="-20px"
              /> */}
              <Icon name="PinMap" size="5xs" mt="1" mr="1" color="primary.DEFAULT" />
              <Text size="xs">{place}</Text>
            </Flex>
          </Flex>
        </Flex>

        <Flex direction="column" position="absolute" top="-30px" right="-25px">
          <Flex
            bgColor="primary.DEFAULT"
            align="center"
            justify="center"
            borderRadius="100%"
            border="solid 6px"
            boxSizing="border-box"
            p="4"
            borderColor="#95c34d"
            width="70px"
            height="70px"
            direction="column"
          >
            <Text textAlign="center" color="white" size="lg">
              {co2}
            </Text>
            <Text textAlign="center" color="white" mt="-1" size="xs">
              tCO₂
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Text size="sm" px="2" color="grey" my="2">
        {type}
      </Text>

      <Flex align="center" px="2" flexWrap="wrap" mt="2" mb="4" gap="2">
        {(impacts as string[]).map((impact, index) => (
          <Tag size="sm" key={`_${index}`} colorScheme="green">
            {impact}
          </Tag>
        ))}
      </Flex>
      <Flex my="2" position="relative">
        <TadaInno
          position="absolute"
          left="-6px"
          width="46px"
          height="50px"
          top="-30px"
          color="primary.DEFAULT"
          zIndex="0"
        />
        <Flex maxW="360px" p="2" zIndex="1">
          {description}
        </Flex>
      </Flex>
    </Flex>
  )
}

const CarbonProjects: FC<BoxProps> = () => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' })
  const memoData = useMemo(() => carbonData(), [])

  return (
    <Container variant="full" bgColor="#95c34d">
      <Container variant="boxed">
        <Heading size="lg" color="white" textAlign="center">
          compenser nos émissions grâce à des projets carbone locaux
        </Heading>
        <Text size="xl" color="white" textAlign="center" mt="3" mb="10">
          on a décidé de compenser les émissions carbone générées par la production et le transport de nos pommes grâce
          à des agriculteurs français engagés dans la transition écologique et désireux de séquestrer du carbone grâce à
          leurs parcelles agricoles
        </Text>
        <Carousel
          centerPadding="30px"
          draggable
          boxedControls
          data={memoData}
          SlideComponent={CarbonItem}
          itemsPerPage={isMobile ? 1 : 3}
          hasDots
          centerMode={false}
          showControls={false}
        />
      </Container>
    </Container>
  )
}

export default CarbonProjects
