import { Heading, Box, Flex, useBreakpointValue } from '@chakra-ui/react'

import { s3ImageURL } from '@miimosa/common'
import { Icon } from '@miimosa/design-system'
import { FullWidthContainer, BoxedContainer, Image } from '@miimosa/design-system'

const HeroFAQ = () => {
  const youpiSize = useBreakpointValue({ base: 'sm', md: 'lg' }, { fallback: 'md' })
  const titleSize = useBreakpointValue({ base: 'lg', md: '2xl' }, { fallback: 'md' })
  const footerIllustrationPath = useBreakpointValue(
    { base: 'bottom-hero-faq-mobile.svg', md: 'illus-bottom-manifest.svg' },
    { fallback: 'md' }
  )

  return (
    <FullWidthContainer bgColor="#B2E2F5" position="relative" overflow="hidden">
      <BoxedContainer height={{ base: '386px', md: '410px' }} position="relative">
        <Box
          position="absolute"
          display={{ base: 'block', md: 'none' }}
          width="33px"
          top={{ base: '160px' }}
          height="33px"
          left={{ base: '35px' }}
          transform="rotate(120deg)"
        >
          <Image alt="innocent" src={s3ImageURL('red_butterfly.svg')} fill style={{ objectFit: 'cover' }} />
        </Box>
        <Box position="absolute" left={{ base: '-20px', md: '30px' }} top={{ base: '2px', md: '60px' }}>
          <Image src={s3ImageURL('innocent-falling-leave-outline.svg')} alt="" width="50" height="43" />
        </Box>
        <Flex>
          <Heading size={titleSize} color="#000" mt={{ base: '40px', md: 20 }}>
            <Icon name="YoupiLeft" w={youpiSize} color="#74AA50" />
            foire aux questions
            <Icon name="YoupiRight" w={youpiSize} color="#74AA50" />
          </Heading>
        </Flex>
      </BoxedContainer>
      <Box position="absolute" width="100%" bottom="0" height={{ base: '110px', md: '346px', '2xl': '590px' }} left="0">
        <Image
          alt="innocent"
          src={s3ImageURL(footerIllustrationPath || 'illus-bottom-manifest.svg')}
          fill
          style={{ objectFit: 'cover' }}
        />
      </Box>
    </FullWidthContainer>
  )
}

export default HeroFAQ
