import { Container, Box, Heading, Text, useBreakpointValue } from '@chakra-ui/react'
import Image from 'next/image'
import NextLink from 'next/link'

import { s3ImageURL } from '@miimosa/common'
import { Button } from '@miimosa/design-system/components'

const TwoYears = () => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' })

  return (
    <Container variant="full" position="relative" bgColor="#a0d884" mt="-3px">
      <Box
        zIndex="0"
        position="absolute"
        top={{ base: '0px', md: '0px' }}
        width="100%"
        height={{ base: '490px', md: '384px' }}
      >
        <Image src={s3ImageURL(`xl-grey-bubble.svg`)} alt="" fill style={{ objectFit: 'cover' }} />
      </Box>
      <Container zIndex="1" variant="boxed" mb="20" position="relative">
        <Box position={isMobile ? 'relative' : 'absolute'} left={{ base: 0, md: '80px' }} top={{ base: 0, md: '60px' }}>
          <Image src={s3ImageURL(`coin_euro.svg`)} alt="" width="108" height="108" />
        </Box>
        <Heading size="lg" mt="4" color="text" textAlign="center">
          2 ans
        </Heading>
        <Text size="md" mt="4" textAlign="center" px="1" maxW={{ md: '580px' }}>
          depuis qu’innocent a lancé son opération <br />
          <span style={{ fontFamily: 'innocent-headline_bold', color: '#E87722' }}>la grande classe verte</span>, pour
          accompagner des projets de revégétalisation et vous permettre de les soutenir aussi. Un petit coup de pousse
          pour la nature, avec vous, plus nous, plus tous ceux qui le veulent
        </Text>
        <NextLink passHref href="/projects">
          <Button mt="6" variant="secondary">
            voir tous les projets
          </Button>
        </NextLink>
      </Container>
    </Container>
  )
}

export default TwoYears
