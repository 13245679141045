import { Box, Container, Heading, Flex, Text, Link, useBreakpointValue } from '@chakra-ui/react'

import { s3ImageURL } from '@miimosa/common'
import { Button } from '@miimosa/design-system/components'
import { Image, Icon } from '@miimosa/design-system/components'

const Hero = () => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' })
  const headerIllustrationPath = useBreakpointValue(
    { base: 'innocent-illus-top-mobile.svg', md: 'header-sub-illu.svg' },
    { fallback: 'md' }
  )
  const footerIllustrationPath = useBreakpointValue(
    { base: 'illus_bas_etiquette-mobile.svg', md: 'illus_bas_etiquette.svg' },
    { fallback: 'md' }
  )

  const helpContent = (
    <>
      <Icon name="YoupiLeft" color="#74AA50" />
      <Heading size="md" color="bg.0" textAlign="center">
        aidez-nous à<br />
        revégétaliser
        <br />
        partout en France
      </Heading>
      <Icon name="YoupiRight" color="#74AA50" />
    </>
  )

  return (
    <Container variant="full" bgColor="#B2E2F5" position="relative" overflow="hidden">
      <Box
        position="absolute"
        width="100%"
        top={{ base: '-20px', md: '-80px', '2xl': '-150px' }}
        height={{ base: '150px', md: '245px', '2xl': '425px' }}
        left="0"
      >
        <Image
          alt="innocent"
          src={s3ImageURL(headerIllustrationPath || 'header-sub-illu.svg')}
          fill
          style={{ objectFit: 'cover' }}
        />
      </Box>
      <Box display={{ base: 'none', md: 'block' }} position="absolute" top="166px" left="200px">
        <Image alt="innocent" src={s3ImageURL('innocent-falling-leave.svg')} width="50" height="43" />
      </Box>
      <Container
        variant="boxed"
        pt={{ base: '30px', md: 14, '2xl': '160px' }}
        pb={{ base: '120px', md: 10, '2xl': '140px' }}
        position="relative"
      >
        <Flex
          display={{ base: 'none', md: 'flex' }}
          align="center"
          position="absolute"
          right={{ md: '-80px', '2xl': '-40px' }}
          top={{ md: '170px', '2xl': '240px' }}
          transform="rotate(-8deg)"
        >
          {helpContent}
        </Flex>
        <Flex direction="column" align="center" mt="8">
          <Text fontFamily="innocent-super_scrawl" color="secondary.TERTIARY" fontSize="50px" mb="2">
            REJOIGNEZ
          </Text>
          <Image
            alt="logo classe verte"
            src={s3ImageURL('logo_classe_verte.svg')}
            width={useBreakpointValue({ base: '187', md: '328' }, { fallback: 'md' })}
            height={useBreakpointValue({ base: '60', md: '105' }, { fallback: 'md' })}
          />
        </Flex>
        <Flex display={{ base: 'flex', md: 'none' }} mt="16" align="center">
          {helpContent}
        </Flex>
        <Flex
          direction="column"
          textAlign="center"
          mt={{ base: '12', md: '110px' }}
          mb={{ base: '6', md: '110px' }}
          align="center"
          maxW={{ base: '320px', md: '608px' }}
          zIndex={10}
        >
          <Heading size="sm" color="secondary.TERTIARY">
            La grande classe verte c&apos;est fini. Merci pour vos petits coups de pousse qui ont aidé les agriculteurs
            français à investir dans leurs projets de transition écologique.
          </Heading>
          <Link
            href="https://www.innocent.fr/ce-que-nous-faisons-pour-la-planete"
            target="_blank"
            textDecoration="none"
            _hover={{
              textDecoration: 'none',
            }}
            mt={6}
          >
            <Button variant="secondary" color="secondary.SECOND" _hover={{ color: 'secondary.TERTIARY' }}>
              ce que nous faisons pour la planète
            </Button>
          </Link>
        </Flex>
      </Container>
      <Box
        position="absolute"
        width="29px"
        top={{ base: '160px', md: '366px' }}
        height="29px"
        left={{ base: '60px', md: '100px' }}
      >
        <Image alt="innocent" src={s3ImageURL('red_butterfly.svg')} fill style={{ objectFit: 'cover' }} />
      </Box>
      <Box
        position="absolute"
        width="29px"
        height="29px"
        top={{ base: '280px', md: '366px' }}
        right={{ base: '60px', md: '114px' }}
      >
        <Image
          alt="innocent"
          src={s3ImageURL(`${isMobile ? 'orange' : 'red'}_butterfly.svg`)}
          fill
          style={{ objectFit: 'cover' }}
        />
      </Box>
      <Box position="absolute" width="100%" bottom="0" height={{ base: '349px', '2xl': '590px' }} left="0">
        <Image
          alt="innocent"
          priority
          src={s3ImageURL(footerIllustrationPath || 'illus_bas_etiquette.svg')}
          fill
          style={{ objectFit: 'cover' }}
        />
      </Box>
    </Container>
  )
}

export default Hero
