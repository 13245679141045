import { Container, Flex, BoxProps, Heading, Text, useBreakpointValue } from '@chakra-ui/react'
import { Wrapper } from '@googlemaps/react-wrapper'
import dynamic from 'next/dynamic'
import { FC } from 'react'

import type { FrontCollectTypeType, FrontSearchProjectType } from '@miimosa/common/types'

const DynamicGoogleMap = dynamic(() => import('@miimosa/design-system/components/GMapV4/GoogleMap'), {
  ssr: false,
})

interface Props {
  projects: FrontSearchProjectType[]
}

const ProjectsMap: FC<Props & BoxProps> = ({ projects }) => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' })
  const mapHeight = isMobile ? `calc(100vh - 160px)` : 'calc(100vh - 64px - 80px)'
  const defaultCoordinates = { lat: 46.6301994324, lng: 1.85116994381 }

  const projectsMarkers: {
    latitude: number
    longitude: number
    projectId: number
    collectType: FrontCollectTypeType
  }[] = []

  projects.map((project) => {
    projectsMarkers.push({
      projectId: project.projectId,
      collectType: project.collect?.type || 'donation',
      latitude: project.latitude,
      longitude: project.longitude,
    })
  })

  const onClickProject = ({ id }: { id: number }) => {
    const project = projects.find(({ projectId }) => projectId === id)
    const { slug } = project || {}
    if (window && slug) {
      return window.open(`/projects/${slug}`, '_blank')
    }
  }

  const clearSelectedProject = () => null

  return (
    <Container variant="full">
      <Container variant="boxed">
        <Flex direction="column">
          <Heading color="text" size="lg" textAlign="center">
            soutenir des projets de transition écologique proches de chez vous
          </Heading>
          <Text size="xl" mt="2" textAlign="center">
            accompagnés par innocent partout en France pour la revégétalisation
          </Text>
        </Flex>
        <Wrapper apiKey={process.env.NEXT_PUBLIC_GOOGLE_KEY ?? ''} libraries={['places']}>
          <Flex mt="8" flex={isMobile ? 'none' : '1 1 0%'} w="100%" h={mapHeight}>
            <DynamicGoogleMap
              coordinates={defaultCoordinates}
              projectsMarkers={projectsMarkers}
              zoom={6}
              fromHome={false}
              onClickProject={onClickProject}
              clearSelectedProject={clearSelectedProject}
              w="100%"
              h="100%"
              visibleCoordinates={{ ...defaultCoordinates, id: 0 }}
              height={mapHeight}
              propsPinMapUrl="./images/pinMapDefault.svg"
            />
          </Flex>
        </Wrapper>
      </Container>
    </Container>
  )
}

export default ProjectsMap
