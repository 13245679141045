import { Flex, Box, Heading } from '@chakra-ui/react'

import { s3ImageURL } from '@miimosa/common'
import { BoxedContainer, Icon, Image, FullWidthContainer } from '@miimosa/design-system/components'

const GreenClassNumbers = () => {
  return (
    <FullWidthContainer
      bgColor="#B2E2F5"
      color="#000"
      mt={{ base: '-10px', md: 0 }}
      position="relative"
      overflow="hidden"
    >
      <BoxedContainer py={undefined}>
        <Heading size="lg" textAlign="center" color="#000" mt="16">
          quelques chiffres sur la grande classe verte
        </Heading>
        <Flex
          position="relative"
          mt="120px"
          width={{ base: '335px', md: '447px' }}
          direction="column"
          align="center"
          justify="center"
          alignSelf={{ base: 'none', md: 'flex-start' }}
          mb={{ base: '120px', md: 'none' }}
          className="floating"
        >
          <Box
            position="absolute"
            width="29px"
            display={{ base: 'block', md: 'none' }}
            top={{ base: '-80px' }}
            height="29px"
            right={{ base: '20px' }}
            zIndex="1"
            transform="rotate(120deg)"
          >
            <Image alt="innocent" src={s3ImageURL('red_butterfly.svg')} fill style={{ objectFit: 'cover' }} />
          </Box>
          <Box
            width={{ base: '335px', md: '447px' }}
            height={{ base: '261px', md: '321px' }}
            position="absolute"
            zIndex="0"
          >
            <Image src={s3ImageURL('innocent-small-cloud.svg')} fill alt="" />
          </Box>
          <Flex direction="column" align="center" zIndex="1">
            <Flex align="center">
              <Icon name="YoupiLeft" size="sm" color="#FF9E1B" />
              <Heading size="2xl" color="#F3D03E" textAlign="center">
                2022
              </Heading>
              <Icon name="YoupiRight" size="sm" color="#FF9E1B" />
            </Flex>
            <Heading mt="6" textAlign="center" size="lg" maxW="542px">
              on compense l’ensemble des émissions carbone liées à la production de notre jus d’orange
            </Heading>
          </Flex>
        </Flex>
        <Flex
          position="relative"
          direction="column"
          ml={{ base: 'none', md: '120px' }}
          align="center"
          justify="center"
          mt={{ base: '120px', md: '20px' }}
          alignSelf={{ base: 'center', md: 'flex-end' }}
          className="floatingTwo"
        >
          <Box
            position="absolute"
            width={{ base: '43px', md: '29px' }}
            height={{ base: '43px', md: '29px' }}
            top={{ base: '-70px', md: '-60px' }}
            right={{ base: '160px', md: '250px' }}
            zIndex="1"
            transform="rotate(180deg) scale(-1, 1)"
          >
            <Image alt="innocent" src={s3ImageURL('innocent-yellow-flower.svg')} fill style={{ objectFit: 'cover' }} />
          </Box>
          <Box width="1099px" transform="scale(-1, 1)" height="341px" position="absolute" zIndex="0">
            <Image src={s3ImageURL('numbers-cloud3.svg')} fill alt="" />
          </Box>
          <Flex direction="column" align="center" zIndex="1">
            <Heading textAlign="center" size="lg">
              On s’est engagés à soutenir
            </Heading>
            <Flex align="center" mt="6">
              <Icon name="YoupiLeft" size="md" color="#F3D03E" />
              <Heading size="xl" color="#FF9E1B" textAlign="center">
                6 projets de revégétalisation
              </Heading>
              <Icon name="YoupiRight" size="md" color="#F3D03E" />
            </Flex>
            <Heading mt="20px" textAlign="center" width={{ base: '335px', md: '600px' }} size="lg">
              en France et on s’engage en 2023 à en soutenir le double
            </Heading>
          </Flex>
        </Flex>
        <Flex
          position="relative"
          direction="column"
          mt={{ base: '240px', md: '120px' }}
          alignSelf={{ base: 'center', md: 'flex-start' }}
          align="center"
          justify="center"
          className="floating"
        >
          <Box
            position="absolute"
            top={{ base: '-140px', md: '-150px' }}
            right={{ base: '120px', md: '100px' }}
            width={{ base: '33px', md: '29px' }}
            height={{ base: '33px', md: '29px' }}
            zIndex="1"
            transform="rotate(120deg)"
          >
            <Image alt="innocent" src={s3ImageURL('red_butterfly.svg')} fill style={{ objectFit: 'cover' }} />
          </Box>
          <Box width="637px" height="399px" position="absolute" zIndex="0">
            <Image src={s3ImageURL('numbers-cloud2.svg')} fill alt="" />
          </Box>
          <Flex direction="column" width="512px" align="center" zIndex="1">
            <Flex align="center">
              <Icon name="YoupiLeft" size="sm" color="#FF9E1B" />
              <Heading size="2xl" color="#F3D03E" textAlign="center">
                2023
              </Heading>
              <Icon name="YoupiRight" size="sm" color="#FF9E1B" />
            </Flex>
            <Heading mt="6" textAlign="center" size="lg" width={{ base: '335px', md: '540px' }}>
              on compense l’ensemble des émissions carbone liées à la production de notre jus de pommes, en France
            </Heading>
          </Flex>
        </Flex>
        <Flex
          position="relative"
          direction="column"
          mt={{ base: '40px', md: '120px' }}
          alignSelf={{ base: 'center', md: 'flex-end' }}
          align="center"
          justify="center"
          className="floatingTwo"
        >
          <Box
            position="absolute"
            width="33px"
            display={{ base: 'block', md: 'none' }}
            top={{ base: '-60px' }}
            height="33px"
            left={{ base: '20px' }}
            zIndex="1"
            transform="rotate(130deg)"
          >
            <Image alt="innocent" src={s3ImageURL('red_butterfly.svg')} fill style={{ objectFit: 'cover' }} />
          </Box>
          <Box width="800px" height="480px" position="absolute" zIndex="0">
            <Image src={s3ImageURL('numbers-cloud2.svg')} fill alt="" />
          </Box>
          <Flex direction="column" align="center" zIndex="1">
            <Image src={s3ImageURL('coin-white.svg')} width="120" height="120" alt="20cents" />
            <Heading textAlign="center" size="lg" mt="6" width={{ base: '332px', md: '512px' }}>
              en 2023, pour chaque bouteille de jus achetée (ceux avec la jolie étiquette), innocent reverse{' '}
              <span style={{ color: '#E87722' }}>1 euro</span> pour soutenir des projets français de transition
              agroécologique grâce à la revégétalisation
            </Heading>
          </Flex>
        </Flex>
      </BoxedContainer>
      <BoxedContainer py={undefined} pb="150px">
        <Flex
          mt={{ base: '240px', md: '220px' }}
          direction="column"
          align="center"
          justify="center"
          alignSelf={{ base: 'center', md: 'center' }}
          position="relative"
          className="floating"
        >
          <Box
            position="absolute"
            top={{ base: '-80px', md: '-80px' }}
            left={{ base: '20px', md: '90px' }}
            width={{ base: '33px', md: '29px' }}
            height={{ base: '33px', md: '29px' }}
            zIndex="1"
          >
            <Image alt="innocent" src={s3ImageURL('orange_butterfly.svg')} fill style={{ objectFit: 'cover' }} />
          </Box>
          <Box
            position="absolute"
            display={{ base: 'block', md: 'none' }}
            top="220px"
            right="20px"
            width="33px"
            height="33px"
            zIndex="1"
          >
            <Image alt="innocent" src={s3ImageURL('red_butterfly.svg')} fill style={{ objectFit: 'cover' }} />
          </Box>
          <Box width="792px" height="551px" position="absolute" zIndex="0">
            <Image src={s3ImageURL('innocent-small-cloud.svg')} fill alt="" />
          </Box>
          <Flex direction="column" align="center" width={{ base: '335px', md: 'auto' }} zIndex="1">
            <Heading textAlign="center" size="lg">
              On atteindra
            </Heading>
            <Flex align="center" mt="6">
              <Icon name="YoupiLeft" size="md" color="#FF9E1B" />
              <Heading size="2xl" color="#F3D03E" textAlign="center">
                la neutralité carbone
              </Heading>
              <Icon name="YoupiRight" size="md" color="#FF9E1B" />
            </Flex>
            <Heading my="18px" textAlign="center" size="lg">
              en
            </Heading>
            <Flex align="center">
              <Icon name="YoupiLeft" size="sm" color="#FF9E1B" />
              <Heading size="2xl" color="#F3D03E" textAlign="center">
                2025
              </Heading>
              <Icon name="YoupiRight" size="sm" color="#FF9E1B" />
            </Flex>
            <Heading mt="18px" width="615px" textAlign="center" size="lg">
              soit 5 ans plus tôt que prévu
              <br />
              (pour une fois qu’on est en avance)
            </Heading>
          </Flex>
        </Flex>
      </BoxedContainer>
    </FullWidthContainer>
  )
}

export default GreenClassNumbers
