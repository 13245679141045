import { Flex, Box } from '@chakra-ui/react'

import { s3ImageURL } from '@miimosa/common'
import { BoxedContainer, Image, FullWidthContainer } from '@miimosa/design-system/components'

import CommitmentBlock from './CommitmentBlock'
import Ornaments from './Ornaments'

const commitmentsData = [
  {
    align: 'flex-start',
    isLight: true,
    bgColor: '#74AA50',
    title: 'faire les choses bien',
    svgPath: 'apple',
    text: [
      "On est très fiers d’être une entreprise certifiée Bcorp depuis 2018. Être B Corp c’est faire partie d’un collectif d’entreprises qui croient, comme nous, que l’on peut faire des affaires, et être en même temps responsable et éthique. Cette certification rigoureuse aborde tous les aspects de notre activité, de la façon dont on traite nos effectifs à la façon dont on traite la planète. Être B-Corp, c'est plus qu'avoir un joli diplôme à afficher au mur (même s’il est très beau). C'est une chance pour nous de continuer à avancer et de prouver au monde qu'une entreprise peut (et doit) grandir en faisant le bien. On vient tout juste d’être recertifiés et on passe ainsi d’un score de 92,5 points obtenu en 2018 à un score fabuleux de 105,2 points. On n’a jamais autant aimé recevoir des notes.",
    ],
    buttonText: 'découvrir nos engagements BCorp',
    buttonAction: 'https://www.innocent.fr/a-propos-de-nous/bcorp',
  },
  {
    align: 'flex-end',
    isLight: true,
    bgColor: '#00573F',
    svgPath: 'spinach',
    title: 'lutter contre le réchauffement climatique',
    text: [
      "On s’est engagés à atteindre la neutralité carbone en 2025. Cet objectif signifie dans la pratique qu’on s’engage à réduire nos émissions partout où on le peut : dans notre chaine d’approvisionnements, dans nos bureaux, dans notre usine ou encore avec nos petites bouteilles. Et pour les émissions carbone qu’on ne peut pas réduire, on s’engage à les compenser via des programmes de protection et de restauration d’espaces naturels. On s’est d’ailleurs fixé l’objectif ambitieux de contribuer à la restauration de deux millions d'hectares de terres d'ici 2025. Notre trajectoire carbone est vérifiée scientifiquement par le Science Based Targets, et nous permettra d’ici 2030 de réduire nos émissions de 50% par litre par rapport à une base de 2019.",
      'Allez on file, on a du boulot.',
    ],
    buttonText: 'découvrir nos engagements climat',
    buttonAction: 'https://www.innocent.fr/ce-que-nous-faisons-pour-la-planete/lutter-contre-le-changement-climatique',
  },
  {
    align: 'flex-start',
    isLight: false,
    bgColor: '#E2E868',
    svgPath: 'pear',
    width: '726px',
    title: 'notre jus d’orange, la première étape de notre trajectoire carbone',
    text: [
      'Notre trajectoire de décarbonation est très ambitieuse et nous permettra d’atteindre la neutralité carbone; ici à 2025 et de diviser par deux nos émissions d’ici 2030. Et comme il fallait bien commencer quelque part, on a remonté nos manches pour se mettre au travail le plus vite possible et on a travaillé dur pour que notre jus d’orange soit la toute première étape de notre trajectoire carbone. Depuis 2022, il est certifié neutre en carbone* (ce qui veut dire que les émissions liées à sa production ont entièrement été compensées) par l’organisme South Pole, une certification qui reconnaît nos engagements en matière de réduction et de compensation. Comment ? Grâce à des projets de protection et de restauration d’espaces naturels*. Ces projets de compensation de haute qualité nous ont permis de compenser les émissions générées par chaque bouteille de jus d’orange qu’on a vendue en 2022. Et ce n’est pas tout. Pour permettre à un maximum de gens de se joindre à nous, on a lancé « la grande classe verte » une opération pour donner un coup de pousse à la nature, en soutenant des organisations locales pour créer et protéger des espaces naturels en Europe et ici aussi en France, grâce à notre partenaire MiiMOSA...',
    ],
    legend:
      "*En 2022, tous nos purs jus d'orange ont été certifiés neutres pour le climat grâce à la réduction et à la compensation de nos émissions, sur l’ensemble du cycle de vie, sur la base du système de certification des produits neutres pour le climat de South Pole.",
    buttonText: 'découvrir la grande classe verte',
    buttonAction: 'https://www.innocent.fr/lagrandeclasseverte',
  },
  {
    align: 'flex-end',
    isLight: true,
    bgColor: '#74AA50',
    svgPath: 'apple',
    title: 'notre jus de pomme, une étape de plus dans notre trajectoire carbone',
    text: [
      'Et comme on ne comptait pas s’arrêter là, en 2023, on a décidé d’aller un cran plus loin et de nous attaquer à un autre jus emblématique de notre portefeuille en France, notre jus de pommes. Il est maintenant composé de pommes françaises et les émissions carbone liées à sa production sont entièrement compensées en France grâce à des agriculteurs qui séquestrent du carbone dans leurs sols.',
      'Et pour le faire savoir, notre grande opération « la grande classe verte » permet cette année aux consommateurs, pour chaque jus innocent acheté (ceux avec la jolie étiquette) de s’engager, eux aussi, en faveur d’agriculteurs français engagés dans la revégétalisation et désireux d’opérer une transition agroécologique durable dans leurs exploitations.',
    ],
    buttonText: 'découvrir la grande classe verte',
    buttonAction: 'https://www.innocent.fr/lagrandeclasseverte',
  },
]

const Commitments = () => {
  return (
    <FullWidthContainer bgColor="bg.0" color="gray.200" position="relative" overflow="hidden">
      <Ornaments />
      <BoxedContainer
        px={{ base: 'none', md: '16' }}
        py={undefined}
        pt={{ base: '100px', md: 16 }}
        pb={{ base: '0px', md: 16 }}
      >
        <Box
          position="absolute"
          width="29px"
          display={{ base: 'block', md: 'none' }}
          top={{ base: '80px' }}
          height="29px"
          left={{ base: '0px' }}
          transform="rotate(-120deg) scale(1.5, 1.5)"
          zIndex="2"
        >
          <Image alt="innocent" src={s3ImageURL('innocent-yellow-flower.svg')} fill style={{ objectFit: 'cover' }} />
        </Box>

        <Flex position="relative" width={{ base: '100%', md: 'auto' }} mb="40px" alignSelf={commitmentsData[0].align}>
          <Box
            position="absolute"
            width="29px"
            display={{ base: 'none', md: 'block' }}
            top={{ md: '160px' }}
            height="29px"
            left={{ md: '-75px' }}
            transform="rotate(-50deg)"
          >
            <Image alt="innocent" src={s3ImageURL('red_butterfly.svg')} fill style={{ objectFit: 'cover' }} />
          </Box>
          <Box
            position="absolute"
            width="155px"
            top={{ base: '-100px', md: '80px' }}
            height="270px"
            right={{ base: '70px', md: '-115px' }}
          >
            <Image alt="innocent" src={s3ImageURL('innocent-panneau-bcorp.svg')} fill style={{ objectFit: 'cover' }} />
          </Box>
          <Box
            position="absolute"
            width="78px"
            display={{ base: 'none', md: 'block' }}
            top={{ md: '-45px' }}
            height="98px"
            right={{ md: '-20px' }}
            zIndex="1"
          >
            <Image alt="innocent" src={s3ImageURL('innocent-white-flower.svg')} fill style={{ objectFit: 'cover' }} />
          </Box>
          <Box display={{ base: 'block', md: 'none' }} position="absolute" top="-85px" left="110px">
            <Image alt="innocent" src={s3ImageURL('innocent-falling-leave.svg')} width="82" height="70" />
          </Box>
          <Box display={{ base: 'none', md: 'block' }} zIndex="2" position="absolute" bottom="-20px" left="90px">
            <Image alt="innocent" src={s3ImageURL('innocent-falling-leave.svg')} width="82" height="70" />
          </Box>
          <CommitmentBlock {...commitmentsData[0]} />
        </Flex>
        <Flex position="relative" mb="40px" alignSelf={{ base: 'auto', md: commitmentsData[1].align }}>
          <Box
            position="absolute"
            width="29px"
            display={{ base: 'none', md: 'block' }}
            top={{ md: '-25px' }}
            height="29px"
            right={{ md: '200px' }}
            transform="rotate(-120deg)"
          >
            <Image alt="innocent" src={s3ImageURL('innocent-yellow-flower.svg')} fill style={{ objectFit: 'cover' }} />
          </Box>
          <Box
            position="absolute"
            width="29px"
            display={{ base: 'none', md: 'block' }}
            top={{ md: '40px' }}
            height="29px"
            left={{ md: '-100px' }}
            transform="rotate(-10deg)"
          >
            <Image alt="innocent" src={s3ImageURL('orange_butterfly.svg')} fill style={{ objectFit: 'cover' }} />
          </Box>
          <Box
            position="absolute"
            width="61px"
            display={{ base: 'none', md: 'block' }}
            top={{ md: '100px' }}
            height="113px"
            left={{ md: '-50px' }}
            zIndex="1"
          >
            <Image alt="innocent" src={s3ImageURL('innocent-dark-branch.svg')} fill style={{ objectFit: 'cover' }} />
          </Box>
          <Box
            position="absolute"
            width="29px"
            display={{ base: 'none', md: 'block' }}
            bottom={{ md: '20px' }}
            height="29px"
            left={{ md: '-50px' }}
            transform="rotate(-120deg)"
          >
            <Image alt="innocent" src={s3ImageURL('innocent-yellow-flower.svg')} fill style={{ objectFit: 'cover' }} />
          </Box>
          <Box
            position="absolute"
            width="64px"
            display={{ base: 'none', md: 'block' }}
            bottom={{ md: '-140px' }}
            height="89px"
            right={{ md: '120px' }}
            transform="rotate(-120deg)"
          >
            <Image alt="innocent" src={s3ImageURL('innocent-mapple-leaf.svg')} fill style={{ objectFit: 'cover' }} />
          </Box>
          <CommitmentBlock {...commitmentsData[1]} />
        </Flex>
        <Flex position="relative" mb={{ base: 0, md: '40px' }} alignSelf={commitmentsData[2].align}>
          <Box
            position="absolute"
            width="29px"
            display={{ base: 'none', md: 'block' }}
            top={{ md: '240px' }}
            height="29px"
            right={{ md: '-80px' }}
            transform="rotate(-50deg)"
          >
            <Image alt="innocent" src={s3ImageURL('red_butterfly.svg')} fill style={{ objectFit: 'cover' }} />
          </Box>
          <Box
            position="absolute"
            width="138px"
            display={{ base: 'none', md: 'block' }}
            top={{ md: '200px' }}
            height="294px"
            right={{ md: '-350px' }}
            zIndex="1"
          >
            <Image
              alt="innocent"
              src={s3ImageURL('bouteille-inno-orange@2x.png')}
              fill
              style={{ objectFit: 'cover' }}
            />
          </Box>
          <Box
            position="absolute"
            width="29px"
            display={{ base: 'none', md: 'block' }}
            bottom={{ md: '120px' }}
            height="29px"
            right={{ md: '-60px' }}
            transform="rotate(-10deg)"
          >
            <Image alt="innocent" src={s3ImageURL('orange_butterfly.svg')} fill style={{ objectFit: 'cover' }} />
          </Box>
          <Box
            position="absolute"
            width="98px"
            display={{ base: 'none', md: 'block' }}
            bottom={{ md: '180px' }}
            height="90px"
            left={{ md: '-160px' }}
            transform="rotate(-10deg)"
          >
            <Image alt="innocent" src={s3ImageURL('innocent-fougere.svg')} fill style={{ objectFit: 'cover' }} />
          </Box>
          <CommitmentBlock {...commitmentsData[2]} />
        </Flex>
        <Flex position="relative" mb={{ base: 0, md: '40px' }} alignSelf={commitmentsData[3].align}>
          <Box
            position="absolute"
            width="231px"
            display={{ base: 'none', md: 'block' }}
            top={{ md: '140px' }}
            height="319px"
            left={{ md: '-450px' }}
            zIndex="1"
          >
            <Image alt="innocent" src={s3ImageURL('bouteille-inno-pomme@2x.png')} fill style={{ objectFit: 'cover' }} />
          </Box>
          <Box
            position="absolute"
            width="64px"
            display={{ base: 'none', md: 'block' }}
            top={{ md: '80px' }}
            height="89px"
            left={{ md: '-240px' }}
            transform="rotate(-130deg)"
          >
            <Image alt="innocent" src={s3ImageURL('innocent-mapple-leaf.svg')} fill style={{ objectFit: 'cover' }} />
          </Box>
          <CommitmentBlock {...commitmentsData[3]} />
        </Flex>
      </BoxedContainer>
    </FullWidthContainer>
  )
}

export default Commitments
