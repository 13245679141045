import { Box, Flex, Text, Heading, useBreakpointValue } from '@chakra-ui/react'

import { s3ImageURL } from '@miimosa/common'
import { Button, BoxedContainer, Icon, FullWidthContainer, Image } from '@miimosa/design-system/components'
import { IconSize } from '@miimosa/design-system/components/Icon'

const HeroManifest = () => {
  const headerSize = useBreakpointValue({ base: 'lg', md: 'xl' }, { fallback: 'md' })
  const youpiSize = useBreakpointValue({ base: 'xs', md: 'lg' }, { fallback: 'md' }) as IconSize
  const footerIllustrationPath = useBreakpointValue(
    { base: 'illus-bottom-manifest-mobile.svg', md: 'illus-bottom-manifest.svg' },
    { fallback: 'illus-bottom-manifest.svg' }
  )

  return (
    <FullWidthContainer bgColor="bg.0" color="gray.200" position="relative" overflow="hidden">
      <Box display={{ base: 'none', md: 'block' }} position="absolute" top="11px" left="-40px">
        <Image alt="innocent" src={s3ImageURL('innocent-fougere.svg')} width="88" height="90" />
      </Box>
      <Box display={{ base: 'none', md: 'block' }} position="absolute" top="60px" left="200px">
        <Image alt="innocent" src={s3ImageURL('innocent-falling-leave.svg')} width="50" height="43" />
      </Box>
      <Box
        position="absolute"
        width="29px"
        top={{ base: '160px', md: '210px' }}
        height="29px"
        left={{ base: '60px', md: '96px' }}
        transform="rotate(-55deg)"
      >
        <Image alt="innocent" src={s3ImageURL('red_butterfly.svg')} fill style={{ objectFit: 'cover' }} />
      </Box>
      <Box
        position="absolute"
        width="61px"
        display={{ base: 'none', md: 'block' }}
        top={{ md: '-30px' }}
        height="113px"
        right={{ md: '-20px' }}
      >
        <Image alt="innocent" src={s3ImageURL('innocent-dark-branch.svg')} fill style={{ objectFit: 'cover' }} />
      </Box>
      <Box
        position="absolute"
        width="29px"
        top={{ base: '160px', md: '120px' }}
        height="29px"
        right={{ base: '60px', md: '290px' }}
        transform="rotate(-10deg)"
      >
        <Image alt="innocent" src={s3ImageURL('orange_butterfly.svg')} fill style={{ objectFit: 'cover' }} />
      </Box>
      <Box
        position="absolute"
        width="50px"
        top={{ base: '160px', md: '270px' }}
        height="43px"
        right={{ base: '60px', md: '120px' }}
        transform="scale(-1, 1)"
      >
        <Image alt="innocent" src={s3ImageURL('innocent-falling-leave.svg')} width="50" height="43" />
      </Box>
      <BoxedContainer
        pt={{ base: '40px', md: 20, '2xl': '160px' }}
        pb={{ base: '140px', md: 12, '2xl': '140px' }}
        position="relative"
        zIndex="1"
      >
        <Box display={{ base: 'block', md: 'none' }} position="absolute" top="80px" left="-40px">
          <Image alt="innocent" src={s3ImageURL('innocent-fougere.svg')} width="88" height="90" />
        </Box>
        <Box
          position="absolute"
          width="61px"
          display={{ base: 'block', md: 'none' }}
          top={{ base: '-50px' }}
          height="113px"
          right={{ base: '-20px' }}
        >
          <Image alt="innocent" src={s3ImageURL('innocent-dark-branch.svg')} fill style={{ objectFit: 'cover' }} />
        </Box>
        <Box position="absolute" top={{ base: '-15px', md: '-15px' }} left={{ base: '-20px', md: '70px' }}>
          <Image alt="innocent" src={s3ImageURL('innocent-yellow-flower.svg')} width="43" height="43" />
        </Box>
        <Flex align="center">
          <Icon name="YoupiLeft" size={youpiSize} color="#74AA50" />
          <Heading size={headerSize} color="#000" textAlign="center">
            chez innocent,
            <br /> on a des grands rêves
          </Heading>
          <Icon name="YoupiRight" size={youpiSize} color="#74AA50" />
        </Flex>

        <Text size="md" align="center" width={{ base: '335px', md: '654px' }} mt={{ base: '50px', md: '78px' }}>
          Comme celui d’aider les gens à être en bonne santé par exemple. Ou celui de faire un karaoké avec Céline
          Dion*. L’un de nos plus grands rêves c’est de{' '}
          <Text as="span" fontFamily="innocent-headline_bold">
            lutter contre le réchauffement climatique.
          </Text>{' '}
          Plus qu&apos;un rêve, c&apos;est une mission. C&apos;est pour ça que nous avons décidé de
          <Text as="span" fontFamily="innocent-headline_bold">
            {' '}
            réduire de 50% nos émissions de CO2 par litre d&apos;ici 2030
          </Text>{' '}
          et que nous allons
          <Text as="span" fontFamily="innocent-headline_bold">
            {' '}
            absorber le reste en soutenant des projets de reforestation
          </Text>{' '}
          un peu partout dans le monde (ça va envoyer du bois).
          <br />
          <br />
          On croit dur comme fer que c&apos;est{' '}
          <Text as="span" fontFamily="innocent-headline_bold">
            tous ensemble qu&apos;on pourra aller plus loin,
          </Text>{' '}
          et c&apos;est pour ça qu&apos;on invite tous ceux qui le souhaitent à donner un coup de pousse à la nature en
          <Text as="span" fontFamily="innocent-headline_bold">
            {' '}
            soutenant des projets français qui remettent un peu de vert dans nos vies.
          </Text>{' '}
          Pour prendre soin de la nature comme elle prend soin de nous. Vous venez avec nous ?
        </Text>
        <Text width={{ base: '335px', md: '654px' }} textAlign="right" size="md" mt="8px">
          *si jamais vous avez son numéro appelez-nous
        </Text>
        <Button mt="6" variant="secondary" as="a" href="https://www.innocent.fr/lagrandeclasseverte">
          découvrir la grande classe verte
        </Button>
      </BoxedContainer>

      <Box position="absolute" width="100%" bottom="0" height={{ base: '110px', md: '346px', '2xl': '590px' }} left="0">
        <Image
          alt="innocent"
          src={s3ImageURL(footerIllustrationPath || 'illus-bottom-manifest.svg')}
          fill
          style={{ objectFit: 'cover' }}
        />
      </Box>
    </FullWidthContainer>
  )
}

export default HeroManifest
