import { Container, Box, Heading, BoxProps, Grid, GridItem, useBreakpointValue } from '@chakra-ui/react'
import { FC } from 'react'

import { s3ImageURL } from '@miimosa/common'
import type { FrontSearchProjectType } from '@miimosa/common/types'
import { Image } from '@miimosa/design-system/components'

import { ProjectCard } from '@components/ProjectCard'

interface Props {
  projects: FrontSearchProjectType[]
  votes: { slug: string; total_vote_count: number }[]
}

const TwoProjects: FC<Props & BoxProps> = ({ votes, projects }) => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' })

  return (
    <Container variant="full" bgColor="#95c34d" position="relative" overflow="hidden">
      <Box
        position="absolute"
        width="80px"
        display={{ base: 'none', md: 'block' }}
        top={{ md: '200px' }}
        height="79px"
        right={{ md: '-15px' }}
      >
        <Image alt="innocent" src={s3ImageURL('innocent-light-branch.svg')} fill style={{ objectFit: 'cover' }} />
      </Box>
      <Container variant="boxed" position="relative" py={undefined}>
        <Box display={{ base: 'none', md: 'block' }} position="absolute" top="0px" left="160px" zIndex="1">
          <Image alt="innocent" src={s3ImageURL('innocent-falling-leave.svg')} width="82" height="70" />
        </Box>
        <Box position="absolute" top="60px" right="-35px" zIndex="1">
          <Image src={s3ImageURL(`coin-white.svg`)} alt="" width="108" height="108" />
        </Box>
        <Heading
          size="lg"
          color="white"
          mt={10}
          maxW={{ base: '300px', md: '500px', lg: '700px' }}
          mb={6}
          textAlign="center"
        >
          Découvrez les 7 projets de revégétalisation soutenus cette année
        </Heading>
        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap={6}>
          {!isMobile && (
            <Box position="absolute" top="100px" left="-85px" zIndex="0">
              <Image src={s3ImageURL(`coin-white.svg`)} alt="" width="108" height="108" />
            </Box>
          )}
          {projects.slice(0, 8).map((p, index) => {
            const projectVote = votes.find(({ slug: pSlug }) => pSlug === p.slug)

            return (
              <GridItem position="relative" key={index}>
                {index === 1 && (
                  <Box
                    position="absolute"
                    width="61px"
                    display={{ base: 'none', md: 'block' }}
                    bottom={{ md: '40px' }}
                    height="113px"
                    right={{ md: '-50px' }}
                    zIndex="0"
                    transform="scale(-1, 1)"
                  >
                    <Image
                      alt="innocent"
                      src={s3ImageURL('innocent-dark-branch.svg')}
                      fill
                      style={{ objectFit: 'cover' }}
                    />
                  </Box>
                )}
                <ProjectCard
                  project={p}
                  zIndex="1"
                  voteCollected={projectVote?.total_vote_count}
                  isBordered
                  withOwner={true}
                  hideQR
                />
              </GridItem>
            )
          })}
        </Grid>
      </Container>
      <Box
        position="absolute"
        width="78px"
        display={{ base: 'none', md: 'block' }}
        bottom={{ md: '250px' }}
        height="90px"
        left={{ md: '-20px' }}
      >
        <Image alt="innocent" src={s3ImageURL('innocent-white-flower.svg')} fill style={{ objectFit: 'cover' }} />
      </Box>
    </Container>
  )
}

export default TwoProjects
