import { Box, Container, Grid, Heading, useBreakpointValue } from '@chakra-ui/react'
import { FC } from 'react'

import { s3ImageURL } from '@miimosa/common'
import { type FrontSearchProjectType } from '@miimosa/common/types'
import { Image, Carousel, ConditionalWrapper } from '@miimosa/design-system/components'

import { ProjectCard } from '@components/ProjectCard'

interface Props {
  teasing: FrontSearchProjectType[]
  completed: FrontSearchProjectType[]
}

const renderCards = (p: FrontSearchProjectType, i: number) => (
  <Box position="relative" key={`${i}_project_card`} px="2" width="100%">
    {i === 2 && (
      <Box
        position="absolute"
        width="61px"
        display={{ base: 'none', md: 'block' }}
        bottom={{ md: '40px' }}
        height="113px"
        left={{ md: '-40px' }}
        zIndex="0"
        transform="scale(1, 1)"
      >
        <Image alt="innocent" src={s3ImageURL('innocent-dark-branch.svg')} fill style={{ objectFit: 'cover' }} />
      </Box>
    )}
    <ProjectCard showButton={false} project={p} zIndex="1" />
  </Box>
)

const ResponsiveCarousel: FC<{
  projects: FrontSearchProjectType[]
  title: string
  isMobile: boolean
  mt: number
}> = ({ projects, title, isMobile, mt }) => {
  if (!projects || projects.length == 0) {
    return <></>
  }

  return (
    <>
      <Heading size="lg" color="white" mb="8" mt={mt}>
        {title}
      </Heading>
      {!isMobile && (
        <Grid
          width="100%"
          justifyItems="center"
          templateColumns={{ base: 'none', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' }}
          gap="16px"
        >
          {projects.map((p, i) => renderCards(p, i))}
        </Grid>
      )}
      {isMobile && (
        <Carousel
          centerPadding="30px"
          draggable
          boxedControls
          data={projects}
          SlideComponent={renderCards}
          itemsPerPage={1}
          hasDots
          centerMode
          showControls={false}
        />
      )}
    </>
  )
}

const UpcomingProjects: FC<Props> = ({ teasing, completed }) => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' })
  const topIllustrationPath = useBreakpointValue(
    { base: 'innocent-double-wave-lime-mobile.svg', md: 'innocent-double-wave-lime.svg' },
    { fallback: 'md' }
  )

  return (
    <Container
      variant="full"
      mt={{ base: '-20px', md: '-40px', lg: '-50px' }}
      position="relative"
      bgImage={s3ImageURL(topIllustrationPath || 'innocent-double-wave-lime.svg')}
      bgSize="cover"
      pt={{ base: 12, md: 16 }}
      pb={{ base: 20, md: 24 }}
      px={undefined}
    >
      <Box position="absolute" right={{ base: '10px', md: '82px' }} top={{ base: '40px', md: '80px' }}>
        <Image src={s3ImageURL('orange_butterfly.svg')} alt="" width="33" height="33" />
      </Box>
      <Box position="absolute" left="57px" bottom="140px">
        <Image src={s3ImageURL('red_butterfly.svg')} alt="" width="33" height="33" />
      </Box>
      <Box display={{ base: 'none', md: 'block' }} position="absolute" right="315px" bottom="150px" zIndex="0">
        <Image src={s3ImageURL('innocent-dark-branch.svg')} alt="" width="80" height="79" />
      </Box>
      {!!teasing.length && (
        <ConditionalWrapper
          condition={!isMobile}
          wrap={(children) => (
            <Container variant="full" alignSelf="center" alignItems="flex-end" py={0} pb="24px">
              <Container variant="boxed" py="0">
                {children}
              </Container>
            </Container>
          )}
        >
          <ResponsiveCarousel projects={teasing} isMobile={isMobile || false} title="les projets à venir" mt={0} />
        </ConditionalWrapper>
      )}
      {!!completed.length && (
        <ConditionalWrapper
          condition={!isMobile}
          wrap={(children) => (
            <Container variant="full" alignSelf="center" alignItems="flex-end" py={0} pb="24px">
              <Container variant="boxed" py="0">
                {children}
              </Container>
            </Container>
          )}
        >
          <ResponsiveCarousel
            projects={completed}
            isMobile={isMobile || false}
            title="les projets déjà financés !"
            mt={teasing ? 12 : 0}
          />
        </ConditionalWrapper>
      )}
    </Container>
  )
}

export default UpcomingProjects
