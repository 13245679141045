import { Text } from '@chakra-ui/react'

const data = () => {
  return [
    {
      title: 'EARL vignobles Ordonneau',
      type: 'Exploitation viticole',
      region: 'Nouvelle-Aquitaine',
      owner: 'Christophe Ordonneau',
      place: 'Saint-Gènes-de-Fronsac',
      co2: 165,
      impacts: ['Qualité des sols', 'Séquestration carbone', 'Biodiversité'],
      description: (
        <Text size="xs">
          Ce viticulteur engagé est dans une démarche qualitative, installé sur une exploitation familiale depuis plus
          de 60 ans.
          <br />
          <br />
          Pour soutenir son effort, il a décidé de valoriser ses pratiques sous forme de crédit carbone et c&apos;est
          ainsi qu&apos;il a fixé l&apos;objectif de stocker un volume de 165 équivalent tCO2. Cela est rendu possible
          grâce à l&apos;application du biostimulant Nutrigeo.
        </Text>
      ),
    },
    {
      title: 'SAS vignobles Bayle-Carreau',
      type: 'Exploitation viticole',
      owner: 'Cyril Jourdan',
      region: 'Nouvelle-Aquitaine',
      place: 'Cars',
      co2: 86,
      impacts: ['Qualité des sols', 'Séquestration carbone', 'Biodiversité'],
      description: (
        <Text size="xs">
          Ce vignoble est exploité par la famille Jourdan depuis 5 générations ! Cyril met en place une culture
          raisonnée, ce qui lui a permis d&apos;obtenir la certification Terra Vitis et le label HVE3 !
          <br />
          <br />
          Pour soutenir son effort, il a décidé de valoriser ses pratiques sous forme de crédit carbone et c&apos;est
          ainsi qu&apos;il a fixé l&apos;objectif de stocker un volume de 86 équivalent tCO2. Cela est rendu possible
          grâce à l&apos;application du biostimulant Nutrigeo.
        </Text>
      ),
    },
    {
      title: 'SCEA du grand moulin',
      type: 'Exploitation viticole',
      owner: 'Jean-François Réaud',
      region: 'Nouvelle-Aquitaine',
      place: 'Saint-Aubin-de-Blaye',
      co2: 87,
      impacts: ['Qualité des sols', 'Séquestration carbone', 'Biodiversité'],
      description: (
        <Text size="xs">
          Ce viticulteur engagé est dans une démarche qualitative, installée sur la commune de Saint-Aubin-de-Blaye, en
          Gironde. Son exploitation biologique et familiale cumule au total 41 hectares. Il vinifie lui-même et sur son
          exploitation son raisin.
          <br />
          <br />
          Pour soutenir son effort, il a décidé de valoriser ses pratiques sous forme de crédit carbone et c&apos;est
          ainsi qu&apos;il a fixé l&apos;objectif de stocker un volume de 87 équivalent tCO2. Cela est rendu possible
          grâce à l&apos;application du biostimulant Nutrigeo.
        </Text>
      ),
    },
    {
      title: 'SCEA Haut-Sociondo',
      owner: 'Jean-François Réaud',
      type: 'Exploitation viticole',
      region: 'Nouvelle-Aquitaine',
      place: 'Saint-Aubin-de-Blaye',
      co2: 90,
      impacts: ['Qualité des sols', 'Séquestration carbone', 'Biodiversité'],
      description: (
        <Text size="xs">
          Ce viticulteur engagé est dans une démarche qualitative, installée sur la commune de Saint-Aubin-de-Blaye, en
          Gironde. Toute son exploitation est en agriculture biologique. Il commercialise son raisin sous forme de
          raisin de table, et vinifie lui-même, sur son exploitation.
          <br />
          <br />
          Pour soutenir son effort, il a décidé de valoriser ses pratiques sous forme de crédit carbone et c&apos;est
          ainsi qu&apos;il a fixé l&apos;objectif de stocker un volume de 90 équivalent tCO2. Cela est rendu possible
          grâce à l&apos;application du biostimulant Nutrigeo sur une surface totale de 14,85 hectares.
        </Text>
      ),
    },
    {
      title: 'EARL Vignobles Motut',
      type: 'Exploitation viticole',
      owner: 'David Motut',
      region: 'Pays de la Loire',
      place: 'Saint-Gervais',
      co2: 150,
      impacts: ['Qualité des sols', 'Séquestration carbone', 'Biodiversité'],
      description: (
        <Text size="xs">
          Dans cette exploitation viticole familiale depuis 1920, David met tout en place pour mener au mieux ses vignes
          et limiter son impact sur son environnement. Ses deux enfants sont très investis dans l&apos;exploitation et
          souhaitent reprendre le vignoble à la retraite de leur père!
          <br />
          <br />
          Pour soutenir son effort, David a décidé de valoriser ses pratiques sous forme de crédit carbone et c&apos;est
          ainsi qu&apos;il a fixé l&apos;objectif de stocker un volume de 150 équivalent tCO2. Cela est rendu possible
          grâce à l&apos;application du biostimulant Nutrigeo.
        </Text>
      ),
    },
    {
      title: 'Le Tilleul',
      type: 'Grandes cultures',
      owner: 'Sébastien Boury',
      region: 'Normandie',
      place: 'Bacqueville-en-Caux',
      co2: 350,
      impacts: ['Qualité des sols', 'Séquestration carbone', 'Biodiversité'],
      description: (
        <Text size="xs">
          Sébastien, installé sur l&apos;exploitation familiale depuis 1996, s&apos;est reconverti en 2000.
          <br />
          <br />
          Sur 50 % de l&apos;exploitation, il cultive des pommes de terre, des betteraves rouges et des carottes
          biologiques. Ses pommes de terre sont pour la consommation directe, mais également pour des plants. Sur le
          reste de la surface, il cultive des pommes à cidre, des céréales et du lin.
          <br />
          <br /> Sur les 142 hectares que fait la ferme, l&apos;exploitant a choisi de d&apos;utiliser la solution
          Nutrigeo sur un total de 23,36 hectares. Cela représente un stockage total de 350 tonnes équivalent CO2.
        </Text>
      ),
    },
    {
      title: 'SCEA Le Paradis',
      owner: 'Bruno Marquaille',
      type: 'Grandes cultures',
      region: 'Hauts-de-France',
      place: 'Hendecourt-lès-Cagnicourt',
      co2: 195,
      impacts: ['Qualité des sols', 'Séquestration carbone', 'Biodiversité'],
      description: (
        <Text size="xs">
          Cette ferme familiale cultive des céréales, des pois, des haricots, des épinards, des pomme de terre et des
          betteraves.
          <br />
          <br />
          La ferme a défini un projet de réduction de son empreinte carbone sur 5 ans en utilisant notamment comme
          levier une meilleure gestion de ses cultures avec notamment l&apos;implantation de couverts intermédiaires et
          la baisse de l&apos;export des résidus de cultures. L&apos;agriculteur utilise beaucoup d&apos;engrais verts,
          il est convaincu de leur utilité ! L&apos;objectif est aussi de réduire le travail du sol. Pour cela, il
          utilise le biostimulant Nutrigeo sur une surface de 13 hectares (des 195 hectares que fait la ferme au total),
          ce qui permet le stockage de 195 tonnes équivalents CO2.
        </Text>
      ),
    },
    {
      title: 'EARL Loridant',
      owner: 'Ingrid Loridant',
      type: 'Grandes cultures',
      region: 'Hauts-de-France',
      place: 'Berneville',
      co2: 776,
      impacts: ['Qualité des sols', 'Séquestration carbone', 'Biodiversité'],
      description: (
        <Text size="xs">
          Cet agricultrice est connu dans sa région pour ses efforts en matière de maintien de la biodiversité,
          notamment grâce à la présence de Grues Royales dans ses champs ! Il a fait planter 1 300 pieds de miscanthus,
          une sorte de bambou, par une vingtaine d&apos;élèves du Lycée agricole de Tilloy. D&apos;ici deux ans, ils
          devraient mesurer entre 1 et 1,5 mètre de haut et permettre, à terme, de freiner l&apos;érosion du champ et de
          fixer les sédiments.
          <br />
          <br /> Sur la ferme, l&apos;exploitant a choisi de d&apos;utiliser la solution Nutrigeo sur un total de 51,74
          hectares. Cela représente un stockage total de 776 tonnes équivalent CO2.
        </Text>
      ),
    },
    {
      title: 'EARL Caridroit',
      type: 'Grandes cultures',
      owner: 'Loic Caridroit',
      region: 'Hauts-de-France',
      place: 'Haute-Avesnes',
      co2: 433,
      impacts: ['Qualité des sols', 'Séquestration carbone', 'Biodiversité'],
      description: (
        <Text size="xs">
          Dans cette exploitation familiale, l&apos;agriculteur a repris l&apos;exploitation en 2015 de son
          arrière-grand-père ! En agriculture conventionnelle, l&apos;exploitant cultive des céréales, des grandes
          cultures. Il cultive aussi une culture typique de la région : la betterave sucrière !<br />
          <br />
          En parallèle, l&apos;agriculteur s&apos;inscrit dans une démarche de dynamisation des sols avec Gaïago. Sur
          les 125 hectares que fait sa ferme, il a choisi d&apos;utiliser le biostimulant Nutrigéo sur un total de 28,88
          hectares. Cela correspond à un total de 433 tonnes équivalents CO2 !
        </Text>
      ),
    },
    {
      title: 'EARL Maraîchers Arnout',
      type: 'Grandes cultures',
      owner: 'Eric Arnout',
      region: 'Hauts-de-France',
      place: 'Hantay',
      co2: 193,
      impacts: ['Qualité des sols', 'Séquestration carbone', 'Biodiversité'],
      description: (
        <Text size="xs">
          Eric est installé sur la ferme familiale depuis 2011 et poursuit la production historique (depuis 1904) que
          sont les légumes : tomates, poireaux, céleris, carottes, courgettes, blé et maïs.
          <br />
          <br />
          La ferme a défini un projet de réduction de son empreinte carbone sur 5 ans en utilisant notamment comme
          levier une meilleure gestion de ses cultures avec l&apos;implantation de couverts intermédiaires et la baisse
          de l&apos;export des résidus de cultures. L&apos;agriculteur a choisi d&apos;appliquer le biostimulant
          Nutrigeo sur 12,84 hectares de sa ferme. Cela permet la séquestration de 193 tonnes équivalents CO2 sur 5 ans
          !
        </Text>
      ),
    },
  ]
}

export default data
