import { Flex, Heading, useBreakpointValue } from '@chakra-ui/react'
import NextLink from 'next/link'
import { FC } from 'react'

import { s3ImageURL } from '@miimosa/common'
import { BoxedContainer, FullWidthContainer, Icon } from '@miimosa/design-system/components'
import { Button } from '@miimosa/design-system/components'

const Collected: FC = () => {
  const topIllustrationPath = useBreakpointValue(
    { base: 'innocent-wave-top-orange-mobile.svg', md: 'innocent-wave-top-orange.svg' },
    { fallback: 'md' }
  )

  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' })

  return (
    <FullWidthContainer
      mt="-20px"
      position="relative"
      bgImage={s3ImageURL(topIllustrationPath || 'innocent-wave-top-orange.svg')}
      bgSize="cover"
      overflow="hidden"
    >
      <BoxedContainer
        align="center"
        justify="center"
        py={undefined}
        pt={{ base: 12, md: 16 }}
        pb={{ base: 10, md: 16 }}
        mb="20px"
      >
        <Flex
          align="center"
          width="100%"
          justify={{ base: 'center', md: 'center' }}
          direction={{ base: 'column', md: 'row' }}
        >
          <Flex direction="column" width="100%" justify="center" align="center" mb={{ base: 6, md: 'none' }}>
            <Heading
              size="lg"
              color="bg.0"
              maxW={{ base: '240px', md: 'fit-content' }}
              textAlign={{ base: 'center', md: 'center' }}
            >
              merci, grâce à vous depuis mai 2022
            </Heading>
            <Flex
              mt={{ base: 6, md: '10' }}
              justify="space-between"
              gap="6"
              align="center"
              direction={{ base: 'column', md: 'row' }}
              width="100%"
              px={{ base: 0, md: 16 }}
            >
              <Flex align="center" position="relative" width="fit-content">
                <Icon name="YoupiLeft" position="absolute" left="-50px" top="16%" color="#F3D03E" />
                <Flex direction="column" my="2">
                  <Heading size="2xl" color="#F3D03E" textAlign={{ base: 'center', md: 'center' }}>
                    129 203 €
                  </Heading>
                  <Heading
                    size="sm"
                    fontFamily="innocent-headline_bold"
                    color="white"
                    textAlign={{ base: 'center', md: 'center' }}
                  >
                    ont déjà été collectés
                  </Heading>
                </Flex>
                <Icon name="YoupiRight" position="absolute" right="-50px" top="16%" color="#F3D03E" />
              </Flex>

              <Flex align="center" position="relative" width="fit-content">
                <Icon name="YoupiLeft" position="absolute" left="-50px" top="16%" color="#F3D03E" />
                <Flex direction="column" my="2">
                  <Heading size="2xl" width="fit-content" color="#F3D03E" textAlign={{ base: 'center', md: 'center' }}>
                    10 720 arbres
                  </Heading>
                  <Heading
                    size="sm"
                    fontFamily="innocent-headline_bold"
                    color="white"
                    textAlign={{ base: 'center', md: 'center' }}
                  >
                    ont été plantés
                  </Heading>
                </Flex>
                <Icon name="YoupiRight" position="absolute" right="-50px" top="16%" color="#F3D03E" />
              </Flex>

              <Flex align="center" position="relative" width="fit-content">
                <Icon name="YoupiLeft" position="absolute" left="-50px" top="16%" color="#F3D03E" />
                <Flex direction="column" my="2">
                  <Heading size="2xl" color="#F3D03E" textAlign={{ base: 'center', md: 'center' }}>
                    1608 t. de C02
                  </Heading>
                  <Heading
                    size="sm"
                    fontFamily="innocent-headline_bold"
                    color="white"
                    textAlign={{ base: 'center', md: 'center' }}
                  >
                    ont été capturées
                  </Heading>
                </Flex>
                <Icon name="YoupiRight" position="absolute" right="-50px" top="16%" color="#F3D03E" />
              </Flex>
            </Flex>
            <Heading
              mt={{ base: 6, md: '10' }}
              maxW={{ base: '280px', md: 'fit-content' }}
              color="bg.0"
              size="lg"
              textAlign={{ base: 'center', md: 'center' }}
            >
              et ça,{isMobile && <br />} c’est vraiment pas mal
            </Heading>
          </Flex>
          {/* {isQRCode && (
            <Flex
              width="640px"
              px={{ base: 'none', md: '14' }}
              py="18"
              align="center"
              bgColor={{ base: 'bg.0', md: 'transparent' }}
              direction={{ base: 'column', md: 'row' }}
              backgroundImage={{
                base: 'none',
                md: 'url(' + s3ImageURL('bulle-bg-white.svg') + ')',
              }}
              backgroundPosition="center"
              backgroundRepeat="no-repeat"
              backgroundSize="contain"
              pb={{ base: '40px' }}
            >
              <Image
                src={s3ImageURL(`innocent/innocent-coin.${isMobile ? 'gif' : 'svg'}`)}
                alt=""
                maxWidth={{ base: '100%', md: '108px' }}
                width={{ base: 'auto', md: '108px' }}
                height={{ base: '180px', md: '108px' }}
                mb={{ base: '4', md: 'none' }}
              />
              <Flex direction="column" ml={{ base: 'none', md: '8' }} textAlign={{ base: 'center', md: 'left' }}>
                <Heading size="md" mt="6" color="gray.200">
                  pour aller plus loin
                </Heading>
                <Text width="360px" size="xl" mt="2" color="gray.200">
                  Vous pouvez soutenir directement le projet de votre choix en faisant un don directement sur leur page.
                </Text>
                <Text size="2xl" mt="2" color="gray.200">
                  Pour chaque euro collecté par projet
                  <br />
                </Text>
                <Text size="2xl" color="primary.DEFAULT">
                  innocent reverse également 1 euro.
                </Text>
              </Flex>
            </Flex>
          )} */}
        </Flex>
        <NextLink passHref href="/manifest">
          <Button variant="secondaryW">découvrir notre manifeste</Button>
        </NextLink>
      </BoxedContainer>
    </FullWidthContainer>
  )
}

export default Collected
