import { Text } from '@chakra-ui/react'

const questionsData = () => {
  return [
    {
      title: 'Comment fonctionne la mécanique de l’opération ?',
      text: (
        <Text size="md">
          Pour participer à l’opération, il vous suffit de scanner le QR code sur le côté de vos bouteilles innocent
          (celles avec la jolie étiquette) achetées en magasin. Vous pourrez ensuite découvrir les deux projets du
          moment en visionnant leur vidéo de présentation. Pour chaque vidéo lancée, innocent reverse 1€ au projet.
        </Text>
      ),
    },
    {
      title: 'Est-ce que je peux soutenir plusieurs projets ?',
      text: (
        <Text size="md">
          Il est possible de soutenir plusieurs projets en visionnant les deux vidéos proposées. Par contre, il n’est
          pas possible de soutenir deux fois le même projet.
        </Text>
      ),
    },
    {
      title: 'Est-ce que je peux soutenir financièrement un projet si je n’ai pas acheté de bouteille de jus ?',
      text: (
        <Text size="md">
          Le soutien financier d’innocent à hauteur de 1€ est conditionné au fait de scanner le QR code présent sur
          chaque bouteille de jus (celles avec la jolie étiquette à l’exception des petits formats 300ml). Néanmoins, il
          est tout à fait possible de soutenir le projet de votre choix en faisant un don avec contrepartie après ou
          avant avoir visionné la vidéo, directement sur la page du projet.
        </Text>
      ),
    },
    {
      title: 'Est-ce que j’ai besoin de m’identifier pour visionner une vidéo ?',
      text: (
        <Text size="md">
          Non, il n’est pas obligatoire de s’identifier pour visionner une vidéo et ainsi déclencher le soutien
          financier d’innocent à hauteur de 1€. Néanmoins, si vous décidez de faire un don avec contrepartie pour le
          projet de votre choix, vous aurez alors besoin de vous identifier pour contribuer à la collecte.
        </Text>
      ),
    },
    {
      title: 'Que faire si je ne peux pas visionner la vidéo d’un projet après avoir scanné le QR code ?',
      text: (
        <Text size="md">
          Si mon QR code ne marche pas, je peux tenter de fermer puis rouvrir la page ou d’attendre 15 minutes. Si
          jamais cela ne fonctionne toujours pas, vous pouvez nous écrire à l’adresse email suivante :{' '}
          <Text as="a" href="mailto:bonjour@miimosa.com" style={{ color: '#E87722' }}>
            bonjour@miimosa.com
          </Text>
        </Text>
      ),
    },
    {
      title: 'Comment être averti du montant total reversé aux projets soutenus ?',
      text: (
        <Text size="md">
          Vous pouvez revenir à tout moment sur le site de l’opération, même sans scanner votre QR code. Il vous suffit
          d’y accéder via votre navigateur en tapant l’URL suivante :{' '}
          <Text as="a" href="https://www.lagrandeclasseverte-innocent.fr" target="_blank" style={{ color: '#E87722' }}>
            www.lagrandeclasseverte-innocent.fr
          </Text>{' '}
          Vous pouvez aussi laisser votre email pour être tenu au courant du résultat final des votes dans le champ
          prévu à cet effet en bas de page.
        </Text>
      ),
    },
    {
      title: 'Jusqu’à quand dure l’opération ?',
      text: (
        <Text size="md">
          L’opération dure jusqu’au 31 octobre 2023, vous aurez la possibilité de soutenir 2 projets de transition
          écologique tous les 2 mois (soit 8 au total) en visionnant les vidéos qui les présentent. Après cette date,
          vous pourrez revenir voir les projets soutenus mais il ne sera plus possible de les soutenir financièrement.
        </Text>
      ),
    },
    {
      title: 'Comment ont été sélectionnés les projets ?',
      text: (
        <Text as="ul" style={{ listStyleType: 'disc' }} size="md">
          Les projets ont été sélectionnés par les équipes de MiiMOSA et innocent selon les critères suivants :
          <Text as="li" ml="4">
            un besoin de financement pour de la plantation d’arbres (agroforesterie, reforestation)
          </Text>
          <Text as="li" ml="4">
            la protection de la biodiversité
          </Text>
          <Text as="li" ml="4">
            la mise en place de pratiques d’agriculture durable
          </Text>
          <Text as="li" ml="4">
            la résilience face aux aléas climatiques
          </Text>
          <Text as="li" ml="4">
            un siège social en France
          </Text>
        </Text>
      ),
    },
    {
      title: 'Qu’est-ce que le financement participatif ?',
      text: (
        <Text size="md">
          Le financement participatif (crowdfunding en anglais) est un mode de financement permettant au grand public de
          soutenir financièrement et collectivement des projets qui le séduisent. MiiMOSA utilise deux offres de
          financement participatif : le don avec contrepartie et le prêt rémunéré.
        </Text>
      ),
    },
    {
      title: 'MiiMOSA, c’est quoi ?',
      text: (
        <Text size="md">
          MiiMOSA est la plateforme de financement leader en Europe dédiée à la transition agricole et alimentaire.
          Après avoir accompagné 7 000 projets pour 150 millions d’euros de financement (en France et en Belgique),
          MiiMOSA a lancé en janvier 2022 le premier fonds de dette dédié à la thématique. <br />
          <br />
          Labellisée BCorp, l’entreprise s’engage à recréer du lien entre des porteurs de projet et l’ensemble de la
          société, afin que chacun participe à la résolution des enjeux alimentaires, environnementaux, climatiques,
          sanitaires et énergétiques.
        </Text>
      ),
    },
  ]
}

export default questionsData
